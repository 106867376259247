<template>
    <div>
        <van-nav-bar
            title="买卖客户详情"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <div style="padding:16px;">
            <div class="title">
                {{ detail.customeraddress }}
            </div>
            <div>
                <span class="price">￥{{ detail.rent }} - ￥{{ detail.rent2 }}万元</span>
                <van-tag type="primary" size="medium" style="margin-right:10px;">{{ area }}</van-tag>
                <van-tag type="primary" size="medium">{{ dis }}</van-tag>
            </div>
            <div class="desc">
                意向面积：{{ detail.housingarea }} - {{ detail.housingarea2 }}㎡<span></span>楼层：{{ detail.floor[0] }}/{{ detail.floor[1] }}层<br />
                户型：{{ detail.unit[0] }}室{{ detail.unit[1] }}厅{{ detail.unit[2] }}卫<span></span>朝向：{{ detail.face }}<br />
                装修：{{ detail.decoration }}<span></span>付款方式：{{ detail.termsofpayment }}<br />
                客户姓名：{{ detail.customername }}<span></span>客户电话：<p @click="getPhone">查看电话</p><br />
            </div>
        </div>

        <van-popup v-model="show">
            <div style="width:260px;height:50px;text-align:center;line-height:50px;">
                电话号码：{{ phone }}
            </div>
        </van-popup>
    </div>
</template>

<script>
    import { getDealCustomerDetail, rentPhone } from '@/api/api'
    export default {
        data() {
            return {
                show: false,
                detail: {
                    customeraddress: '',
                    rent: '',
                    housingarea: '',
                    floor: [],
                    face: '',
                    termsofpayment: '',
                    decoration: '',
                    customername: '',
                    unit: []
                },
                area: '',
                dis: '',
                faceArr: [],
                phone: ''
            }
        },
        methods: {
            onClickLeft() {
                this.$router.back()
            },
            getPhone() {
                const load = this.$toast.loading({
                    duration: 0,
                    message: '加载中...',
                    forbidClick: true,
                    loadingType: 'spinner',
                })
                rentPhone(this.$route.query.id).then(res => {
                    load.clear()
                    if (res.code === 200) {
                        this.phone = res.data.customertel ? res.data.customertel : '未录入电话'
                        this.show = true
                    } else {
                        this.$toast({
                            duration: 2000,
                            message: '暂无查看权限'
                        })
                    }
                }).catch(() => {
                    load.clear()
                    this.$toast({
                        duration: 2000,
                        message: '暂无查看权限'
                    })
                })
            }
        },
        mounted() {
            const { area, dis } = this.$route.query
            this.area = area
            this.dis = dis
            const load = this.$toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
                loadingType: 'spinner',
            })
            getDealCustomerDetail(this.$route.query.id).then(res => {
                if (res.code === 200) {
                    const floor = res.data.floor.split(',')
                    res.data.floor = floor.length === 2 ? floor : ['--', '--']
                    const unit = res.data.unit.split(',')
                    res.data.unit = unit.length === 3 ? unit : ['--', '--', '--']
                    this.detail = res.data

                    this.$smartDict.getDict(['vua_face', 'vua_decoration', 'vua_buy_payment']).then(({vua_face, vua_decoration, vua_buy_payment}) => {
                        const { face, decoration } = this.detail
                        const f = vua_face.find(e => e.dictValue == face)
                        const d = vua_decoration.find(e => e.dictValue == decoration)
                        this.detail.face = f ? f.dictLabel : '--'
                        this.detail.decoration = d ? d.dictLabel : '--'
                        const data = vua_buy_payment.find(e => e.dictValue == this.detail.termsofpayment)
                        if (data) this.detail.termsofpayment = data.dictLabel
                    })
                }
            }).finally(() => {
                load.clear()
            })
        }
    }
</script>

<style lang="scss" scoped>
    .title{
        font-size: 20px;
        color: #333;
        margin: 10px 0;
    }
    .price{
        font-size: 16px;
        color: orange;
        margin-right: 14px;
    }
    .desc{
        font-size: 14px;
        color: rgb(116, 116, 116);
        margin-top: 14px;
        span{
            display: inline-block;
            width: 1px;
            height: 14px;
            background-color: rgb(151, 151, 151);
            margin: 0 8px;
            position: relative;
            top: 2px;
        }
        p{
            display: inline;
            color: #1989fa;
        }
    }
</style>